/* eslint-disable react/require-default-props */
import { Box, ChakraProps, useStyleConfig } from '@chakra-ui/react';

import React, { ReactElement, ReactNode } from 'react';

interface Props extends ChakraProps {
  children: ReactNode,
  variant?: string
}

const ContentBox = (props: Props): ReactElement => {
  const { variant, children } = props;
  const boxStyles = useStyleConfig('ContentBox', { variant });

  return (
    <Box sx={boxStyles} {...props}>
      {children}
    </Box>
  );
};

export default ContentBox;
